<template>
  <v-container fluid class="px-4 pb-6 pt-0">
    <UpgradeBanner class="mb-8" />

    <v-row class="d-flex flex-wrap px-4">
      <v-col
        cols="12"
        md="3"
        sm="4"
        v-for="(card, index) in cards"
        :key="index"
      >
        <v-row class="my-4">
          <v-layout row wrap class="mr-5 mx-auto">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  @click="setPath(card.path)"
                  :elevation="hover ? 6 : 0"
                  class="mx-auto pt-8"
                  height="280"
                  width="320"
                  outlined
                >
                  <div>
                    <v-icon size="65" :class="`d-flex mb-6`" color="accent">{{
                      card.icon
                    }}</v-icon>
                  </div>
                  <h3 class="text-center px-2">{{ card.title }}</h3>
                  <v-card-subtitle class="pb-0 text-center">{{
                    card.description
                  }}</v-card-subtitle>
                </v-card>
              </template>
            </v-hover>
          </v-layout>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UpgradeBanner from "@/components/billing/upgrade/UpgradeBanner";
export default {
  name: "SignMain",
  components: { UpgradeBanner },
  data: () => ({
    breadcrumbs: [
      {
        text: "Início",
        disabled: false,
        to: "/",
      },
      {
        text: "Conecta Sign",
        disabled: false,
        to: "",
      },
    ],
    cards: [
      {
        type: "icon",
        title: "Assinaturas de e-mail",
        description: "Edite nossos modelos ou crie modelos personalizados",
        icon: "mdi-card-bulleted",
        path: "/sign/template",
        status: true,
      },
      {
        type: "icon",
        title: "Mensagem de férias",
        description:
          "Defina qual mensagem será enviada no Gmail dos colaboradores que estão de férias",
        path: "/sign/templates/vacation-messages",
        status: true,
        icon: "mdi-message-text-clock",
      },
      {
        type: "icon",
        title: "Aplicar em massa",
        description: "Altere as assinaturas do Gmail de vários usuários",
        path: "/sign/users/apply",
        status: true,
        icon: "mdi-account-multiple",
      },
      {
        type: "icon",
        title: "Aplicação automática",
        description:
          "Atualize automaticamente os modelos de assinatura aplicados em seus usuários.",
        path: "/sign/users/sign-automation",
        status: true,
        icon: "mdi-update",
      },
      {
        type: "icon",
        title: "Informações de assinatura",
        description:
          "Configure as informações que poderão ser utilizadas em nos seus modelos de e-mail",
        path: "/settings/profile",
        status: true,
        icon: "mdi-tag",
      },
    ],
  }),

  methods: {
    setPath(path) {
      if (path != null) {
        this.$router.push({ path: path });
      }
    },
  },
};
</script>
